<template>
  <v-container class="container-fluid">
    <v-row>
      <v-col xs="12" sm="12" md="3" lg="3" align="left">
        <v-list three-line class="container-clientes">
          <template v-for="item in itens">
            <v-list-item
              :key="item.idcadastroextracliente"
              class="item"
              :class="
                item.idcadastroextracliente === idcadastroextracliente
                  ? 'select-item'
                  : ''
              "
              @click="setCliente(item)"
            >
              <v-list-item-avatar>
                <v-img src="@/assets/sem-foto.png"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.nome }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-col>
      <v-col xs="12" sm="12" md="9" lg="9" align="left">
        <v-row v-if="cliente">
          <v-col xs="12" sm="6" md="6" lg="6" align="left">
            <span style="font-size: 20px;"
              ><b>{{ nome }}</b></span
            >
            <br />
            <span>{{ documentType }}: {{ cpf }}</span>
            <br />
            <span>E-mail: {{ email }}</span>
            <br />
            <span>Telefone: {{ telefone }}</span>
          </v-col>
          <v-col xs="12" sm="6" md="6" lg="6">
            <v-row class="container-card">
              <div class="card" @click="clickCarrinho">
                <div class="card-body">
                  <v-icon large color="orange darken-2">
                    > fas fa-shopping-cart
                  </v-icon>
                </div>
                <span>Carrinho</span>
              </div>
              <div class="card" @click="clickContato">
                <div class="card-body">
                  <v-icon large color="orange darken-2">
                    > far fa-comment
                  </v-icon>
                </div>
                <span>Iniciar Contato</span>
              </div>
            </v-row>
          </v-col>
          <div class="divider"></div>
          <div v-if="!hadAtividade">
            <div class="container-nenhuma">
              <h3 class="aviso-nenhuma">
                Nenhuma Atividade Recente
              </h3>
            </div>
          </div>
          <div id="container-atividade" v-else class="container-atividade">
            <div style="margin-top:20px; margin-left:30px;">
              <span style="font-size: 20px;">Atividade Recente</span>
            </div>
            <template v-for="atividade in atividades">
              <v-list-item
                :key="atividade.idlognavegacao"
                class="list-atividade"
              >
                <v-list-item-content style="text-align: left;">
                  <v-list-item-title style="color:black; font-weight: bold;">{{
                    getAcao(atividade)
                  }}</v-list-item-title>
                  <v-list-item-subtitle>
                    <span> {{ normalizaTextoAtividade(atividade) }}</span>
                    <br />
                    <span style="color:black; font-weight: bold;">{{
                      getData(atividade)
                    }}</span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as _ from 'lodash'
import moment from 'moment'
import ApiWeb from '../../service/api-web'
import { mapGetters } from 'vuex'
import { LOG_NAV_LOGS } from '@/store/actions/cliente'
export default {
  name: 'Clientes',
  data: () => ({
    atividades: [],
    qtd: 20,
    itens: [],
    cliente: null,
    labelAtividade: {
      LISTA_PRODUTOS: 'Acessou a lista de produtos',
      DETALHES_PRODUTO: 'Visualizou um produto'
    }
  }),
  computed: {
    ...mapGetters(['getFornecedorId']),
    nome() {
      return _.get(this.cliente, 'nome', '')
    },
    documentType() {
      const cpf = _.get(this.cliente, 'cpf', '')
      return cpf.length <= 11 ? 'CPF' : 'CNPJ'
    },
    cpf() {
      const cpf = _.get(this.cliente, 'cpf', '')
      if (cpf.length <= 11) {
        return cpf
          .replace(/\D/g, '')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d)/, '$1.$2')
          .replace(/(\d{3})(\d{1,2})/, '$1-$2')
          .replace(/(-\d{2})\d+?$/, '$1')
      } else {
        return cpf
          .replace(/\D/g, '')
          .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
      }
    },
    email() {
      return _.get(this.cliente, 'email', '')
    },
    telefone() {
      const telefone = _.get(this.cliente, 'telefone', '')
      return telefone
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d)/, '($1) $2')
        .replace(/(\d{5})(\d)/, '$1-$2')
        .replace(/(-\d{4})(\d+?)$/, '$1')
    },
    idcadastroextracliente() {
      return _.get(this.cliente, 'idcadastroextracliente', '')
    },
    hadAtividade() {
      return this.atividades.length !== 0
    }
  },
  methods: {
    clickCarrinho() {
      this.$router.push({
        path: '/fornecedor/novo-pedido',
        query: { cliente: _.get(this.cliente, 'cpf', '') }
      })
    },
    clickContato() {
      this.$emit('iniciarContato', {
        idcliente: _.get(this.cliente, 'idcliente'),
        nomecliente: _.get(this.cliente, 'nome')
      })
    },
    setCliente(item) {
      this.cliente = item
      this.getNavLog()
    },
    getClientes() {
      ApiWeb.v4.vendamais.cliente(this.getFornecedorId, '').then(resp => {
        this.itens = resp.data.result
        if (resp.data.result[0]) {
          this.setCliente(resp.data.result[0])
        }
      })
    },
    getNavLog() {
      this.$store
        .dispatch(LOG_NAV_LOGS, {
          idcliente: _.get(this.cliente, 'idcliente', ''),
          qtd: this.qtd
        })
        .then(data => (this.atividades = data))
    },
    normalizaTextoAtividade(atividade) {
      const acesso = _.get(atividade, 'acessou', '')
      const detalhes = _.get(atividade, 'detalhes', {})
      switch (acesso) {
        case 'DETALHES_PRODUTO':
          return _.get(detalhes, 'produto.nome', '')
        case 'LISTA_PRODUTOS': {
          const pagina = _.get(detalhes, 'pagina', 1) + 1
          const busca = _.get(detalhes, 'buscaTextual')
          const codbarras = _.get(detalhes, 'codigoBarras')
          const categoria = _.get(detalhes, 'categoria')
          const acessouEm = busca
            ? `busca textual '${busca}'`
            : categoria
            ? `categoria '${categoria}'`
            : codbarras
            ? `busca de código de barras '${codbarras}'`
            : 'listagem de produtos'
          return `Navegou na página ${pagina} da ${acessouEm}`
        }
        default:
          return ''
      }
    },
    getAcao(atividade) {
      const acao = _.get(atividade, 'acessou', '')
      if (acao && this.labelAtividade[acao]) {
        return this.labelAtividade[acao]
      } else {
        return 'Acesso desconhecido'
      }
    },
    getData(atividade) {
      const data_criacao = _.get(atividade, 'data_criacao', '')
      return moment(data_criacao).format('DD/MM/YYYY HH:mm')
    }
  },
  created() {
    this.getClientes()
  }
}
</script>

<style lang="scss">
.card {
  margin-right: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f1f3f6;
  width: 60px;
  height: 60px;
}
.container-card {
  display: flex;
  justify-content: right;
  align-items: center;
}
.divider {
  border-style: solid;
  border-width: 2px 2px 2px 2px;
  width: 100%;
}
.container-atividade {
  height: 35vh;
  width: 100vw;
  overflow: auto;
}
.list-atividade {
  background-color: #f1f3f6;
  margin: 10px 20px 0 20px;
}
.item:hover {
  cursor: pointer;
}
.item {
  width: 100%;
}
.content {
  background-color: white;
  overflow: auto;
  min-height: 100%;
}
.select-item {
  background-color: #a9a9a9;
}
.container-clientes {
  background-color: #f1f3f6;
  width: 100%;
  height: 50vh;
  overflow: auto;
}
.aviso-nenhuma {
  color: #a9a9a9;
}
.container-nenhuma {
  margin-top: 10px;
  margin-left: 10px;
  width: 100%;
}
@media (max-width: 1060px) {
  .container-clientes {
    height: 100%;
  }
}
</style>
