<template>
  <v-container>
    <v-row>
      <v-col xs="12" sm="12" md="3" lg="3" align="left">
        <div v-if="hadChat">
          <v-list three-line class="container-chat">
            <template v-for="c in chat">
              <v-list-item
                :key="c.sala"
                class="item"
                :class="c.sala === sala ? 'select-item' : ''"
                @click="abrirChat(c)"
              >
                <v-list-item-avatar>
                  <v-img src="@/assets/sem-foto.png"></v-img>
                  <div class="chat-budge" v-show="c.total_mensagens > 0">
                    {{ c.total_mensagens > 9 ? '+9' : c.total_mensagens }}
                  </div>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ c.nome_usuario }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <div v-if="c.numpedido">
                      <span>
                        Pedido <b>{{ c.numpedido }}</b></span
                      >
                      -
                      <span>{{ c.mensagem }}</span>
                    </div>
                    <div>
                      <span>{{ c.mensagem }}</span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-list>
        </div>
        <div v-else>
          <h3>Não há novas Mensagens...</h3>
        </div>
      </v-col>
      <v-col xs="12" sm="12" md="9" lg="9" align="left">
        <div
          id="container-message"
          class="container-message"
          v-if="connected && !loading"
        >
          <template v-for="mensagem in mensagens">
            <v-list-item :key="mensagem.mensagemUUID">
              <v-list-item-content class="mensagens">
                <v-list-item-title>{{
                  `${mensagem.origem === 'f' ? getUsuarioNome : nomeCliente}`
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  <span>{{ mensagem.mensagem }}</span>
                  <br />
                  <small>{{ mensagem.time }}</small>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div class="inputMessage">
            <v-text-field
              v-model="messagem"
              :append-outer-icon="messagem ? 'fa fa-paper-plane' : ''"
              filled
              outlined
              clear-icon="fa fa-times"
              clearable
              placeholder="Insira sua mensagem aqui..."
              type="text"
              @click:append-outer="enviaMensagem"
              @click:clear="clearMessage"
              @keyup.enter="enviaMensagem"
            ></v-text-field>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from 'vuex'
import * as _ from 'lodash'
import io from 'socket.io-client'
import { uuid } from 'uuidv4'
import moment from 'moment'
import ApiWeb from '../../service/api-web'
export default {
  name: 'MensagensGerais',
  props: ['idClienteSelecionado', 'nomeClienteSelecionado'],
  data: () => ({
    mensagens: [],
    messagem: '',
    conexao: null,
    conexaoChat: null,
    connectionRetry: 0,
    socketUrl: null,
    chat: [],
    page: 1,
    perpage: 300,
    loading: true,
    idcliente: null,
    nomeCliente: 'Cabeçudo',
    sala: null,
    canal: '',
    numpedido: null,
    connected: false,
    failure: false,
    SOCKET_RETRIES: 5
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'getUsuarioNome']),
    hadChat() {
      return this.chat.length !== 0
    }
  },
  created() {
    this.getUrlSocket()
  },
  destroyed() {
    if (this.conexao) {
      this.conexao.disconnect()
    }
  },
  watch: {
    mensagens(val) {
      if (val.length) {
        setTimeout(() => {
          var objDiv = document.getElementById('container-message')
          objDiv.scrollTop = objDiv.scrollHeight
        }, 1)
      }
    },
    socketUrl(val) {
      if (val) {
        this.initSocket()
      }
    }
  },
  methods: {
    getUrlSocket() {
      ApiWeb.v4.chat
        .getUrl()
        .then(resp => {
          const { socketUrl } = resp.data
          if (!socketUrl) {
            this.$vueOnToast.pop(
              'error',
              'Erro ao buscar configuração do Socket'
            )
          } else {
            this.socketUrl = socketUrl
          }
        })
        .catch(err => {
          this.$vueOnToast.pop('error', err)
        })
    },
    initSocket() {
      if (this.conexao) {
        this.conexao.disconnect()
      }
      const conexao = io(`${this.socketUrl}/fornecedor`)
      this.connectionRetry = (this.connectionRetry || 0) + 1
      conexao.on('new-notification-message', this.handlerNewNotificationMessage)
      conexao.on('handshake-success', () => {
        this.loading = false
        this.buscaChat()
      })
      conexao.on('notification-read-message', this.handlerReadMessages)
      if (this.idcliente || this.idClienteSelecionado) {
        conexao.on('chat-cliente', this.handlerChatCliente)
      } else {
        conexao.on('chat-aberto', this.handlerChatAberto)
      }
      setTimeout(() => conexao.emit('handshake', this.getFornecedorId), 500)
      this.conexao = conexao
    },
    buscaChat(page = 1, perpage = 300) {
      if (this.idClienteSelecionado) {
        this.idcliente = this.idClienteSelecionado
        this.conexao.emit('list-chat-cliente', {
          idfornecedor: this.getFornecedorId,
          idcliente: this.idcliente,
          page,
          perpage
        })
      } else {
        this.conexao.emit('list-chat-aberto', {
          idfornecedor: this.getFornecedorId,
          page,
          perpage
        })
      }

      this.page = page
      this.perpage = perpage
    },
    handlerChatCliente(chatbertos) {
      const chat = (chatbertos || []).map(item => ({
        numpedido: item.numpedido,
        idcliente: _.get(
          item,
          'idcliente',
          _.get(item, 'ultima_mensagem.idusuario')
        ),
        origem: _.get(item, 'ultima_mensagem.origem', 'c'),
        mensagem: _.get(item, 'ultima_mensagem.mensagem'),
        sala: item.sala,
        canal: item.canal,
        datamensagem: _.get(item, 'ultima_mensagem.datamensagem'),
        nome_usuario:
          _.get(item, 'nome_usuario') ||
          this.nomeClienteSelecionado ||
          'Sem Nome',
        total_mensagens: item.nao_lida || 0
      }))
      this.chat = _.uniqBy([...this.chat, ...chat], 'sala')
    },
    handlerChatAberto(chatbertos) {
      const chat = (chatbertos || []).map(item => ({
        numpedido: item.numpedido,
        idcliente: _.get(
          item,
          'idcliente',
          _.get(item, 'ultima_mensagem.idusuario')
        ),
        origem: _.get(item, 'ultima_mensagem.origem', 'c'),
        mensagem: _.get(item, 'ultima_mensagem.mensagem'),
        sala: item.sala,
        canal: item.canal,
        datamensagem: _.get(item, 'ultima_mensagem.datamensagem'),
        nome_usuario: _.get(item, 'nome_usuario', 'Sem nome'),
        total_mensagens: item.total_mensagens || 1
      }))
      this.chat = _.uniqBy([...this.chat, ...chat], 'sala')
    },
    handlerNewNotificationMessage(novamensagem) {
      const {
        sala,
        mensagem,
        total_mensagens,
        datamensagem,
        origem
      } = novamensagem
      const chats = _.cloneDeep(this.chat || [])
      const newChat = _.find(chats, { sala })
      if (newChat) {
        const filterchat = _.uniqBy(
          [
            ...chats.filter(item => item.sala !== sala),
            {
              ...newChat,
              mensagem: mensagem,
              datamensagem: datamensagem,
              origem,
              total_mensagens:
                origem === 'c'
                  ? Number(newChat.total_mensagens || 0) +
                    (total_mensagens || 1)
                  : 0
            }
          ],
          'sala'
        )
        this.chat = filterchat
      } else {
        this.chat = _.uniqBy(
          [
            {
              ...novamensagem,
              total_mensagens: total_mensagens || 1
            },
            ...this.chat
          ],
          'sala'
        )
      }
    },
    handlerReadMessages(read) {
      const chats = _.cloneDeep(this.chat || [])
      this.chat = chats.reduce((rooms, chat) => {
        if (chat.sala === read.sala) {
          return [
            ...rooms,
            {
              ...chat,
              total_mensagens: 0
            }
          ]
        }
        return [...rooms, chat]
      }, [])
    },
    abrirChat(item) {
      this.chat.map(chat => {
        if (chat.sala == item.sala) {
          chat.total_mensagens = 0
        }
        return chat
      })

      this.idcliente = item.idcliente
      this.nomeCliente = item.nome_usuario
      this.sala = item.sala
      this.canal = item.canal || ''
      this.numpedido = item.numpedido
      this.startChat()
    },
    startChat() {
      if (this.conexaoChat) {
        this.conexaoChat.disconnect()
        this.connected = true
      }

      const conexaoChat = io(`${this.socketUrl}/${this.canal || ''}`, {
        autoConnect: true
      })

      this.mensagens = []
      this.page = 1
      this.loading = true
      this.connectionRetry = this.connectionRetry + 1

      conexaoChat.on('new-message-received', this.handlerNovaMensagem)
      conexaoChat.on('new-message-sent', this.handlerNovaMensagem)
      conexaoChat.on('message-historic', this.handlerHistoric)
      conexaoChat.on('handshake-success', () => {
        this.connected = true
        this.loading = false
        this.listarMensagens()
      })
      conexaoChat.emit('mark-as-read', {
        sala: this.sala,
        idfornecedor: this.getFornecedorId,
        origem: 'c'
      })
      setTimeout(() => conexaoChat.emit('handshake-room', this.sala), 500)
      this.conexaoChat = conexaoChat
      setTimeout(() => {
        if (this.loading && this.mensagens.length === 0) {
          if (this.connectionRetry < this.SOCKET_RETRIES) {
            this.startChat()
          } else {
            this.failure = true
          }
        }
      }, 20000)
    },
    enviaMensagem() {
      if (this.messagem) {
        const mensagemUUID = uuid()
        const novamensagem = {
          datamensagem: new Date().toLocaleString(),
          idfornecedor: this.getFornecedorId,
          lida_origem: [],
          metadados: {},
          idusuario: '0',
          mensagem: this.messagem,
          origem: 'f',
          mensagemUUID,
          time: '',
          numpedido: this.numpedido
        }

        this.conexaoChat.emit('new-chat-message', {
          idfornecedor: this.getFornecedorId,
          idusuario: this.idcliente,
          idcliente: this.idcliente,
          numpedido: this.numpedido,
          codpedido: this.numpedido,
          txt: this.messagem,
          mensagem: this.messagem,
          origem: 'f',
          mensagemUUID,
          timestamp: null
        })
        this.mensagens.push(novamensagem)
        this.clearMessage()
      }
    },
    clearMessage() {
      this.messagem = ''
    },
    listarMensagens() {
      this.conexaoChat.emit('listar-mensagens', {
        idfornecedor: this.getFornecedorId,
        sala: this.sala,
        page: this.page || 1,
        perpage: this.perpage || 300
      })
    },
    handlerNovaMensagem(mensagem) {
      const datamensagem = moment()
      const mensagemUUID = _.get(mensagem, 'mensagemUUID', uuid())
      const mensages = _.cloneDeep(this.mensagens)
      const findIndex = _.findIndex(mensages, { mensagemUUID })
      if (findIndex >= 0 && mensagem.origem === 'f') {
        mensages[findIndex] = {
          ...mensages[findIndex],
          time: datamensagem.format('DD/MM HH:mm')
        }
        this.mensagens = mensages
      } else {
        this.mensagens = [
          ...this.mensagens,
          {
            mensagemUUID: mensagemUUID || uuid(),
            idfornecedor: _.get(mensagem, 'idfornecedor'),
            idusuario: _.get(
              mensagem,
              'idcliente',
              _.get(mensagem, 'idusuario')
            ),
            lida_origem: [],
            mensagem: _.get(
              mensagem,
              'mensagem',
              _.get(mensagem, 'txt', _.get(mensagem, 'text'))
            ),
            metadados: _.get(mensagem, 'metadados', {}),
            origem: _.get(mensagem, 'origem', 'c'),
            numpedido: _.get(
              mensagem,
              'numpedido',
              _.get(mensagem, 'codpedido', null)
            ),
            datamensagem: datamensagem.toLocaleString(),
            time: datamensagem.format('DD/MM HH:mm')
          }
        ]
      }
      this.conexaoChat.emit('mark-as-read', {
        sala: this.sala,
        idfornecedor: this.getFornecedorId,
        origem: 'c'
      })
    },
    handlerHistoric(mensages) {
      this.mensagens = [
        ...this.mensagens,
        ...mensages.map(msg => ({
          ...msg,
          time: moment(_.get(msg, 'datamensagem')).format('DD/MM HH:mm'),
          mensagemUUID: uuid()
        }))
      ].reverse()
      this.conexao.emit('mark-as-read', {
        sala: this.sala,
        idfornecedor: this.getFornecedorId,
        origem: 'c'
      })
    }
  }
}
</script>

<style lang="scss">
.container-message {
  width: 100%;
  overflow: auto;
  height: 60vh;
}
.mensagens {
  text-align: left;
}
.inputMessage {
  width: 90%;
}
.container-chat {
  background-color: #f1f3f6;
  width: 100%;
  height: 60vh;
  overflow: auto;
}
.item:hover {
  cursor: pointer;
}
.item {
  width: 100%;
}
.select-item {
  background-color: #a9a9a9;
}
.chat-budge {
  background-color: #ff1e19;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  font-size: 12px;
  color: #ffff;
}

@media (max-width: 1060px) {
  .container-chat {
    height: 100%;
  }
}
</style>
