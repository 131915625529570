<template>
  <div v-if="!resourceActived">
    <a href="https://bit.ly/3TdQYyM" target="_blank">
      <img src="@/assets/mandala_omnichannel.png" class="banner" />
    </a>
  </div>
  <div v-else>
    <v-container>
      <v-row class="tab-container">
        <v-col
          class="tab"
          :class="{ 'tab-active': isMensagemActive }"
          @click="setAba('MENSAGENS')"
        >
          MENSAGENS GERAIS
        </v-col>
        <v-col
          class="tab"
          :class="{ 'tab-active': isClienteActive }"
          @click="setAba('CLIENTES')"
        >
          CLIENTES
        </v-col>
      </v-row>
      <div>
        <div v-if="isMensagemActive">
          <MensagensGerais
            :idClienteSelecionado="idcliente"
            :nomeClienteSelecionado="nomecliente"
          />
        </div>
        <div v-if="isClienteActive">
          <Clientes @iniciarContato="iniciarContato" />
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import * as _ from 'lodash'
import { mapGetters } from 'vuex'
import ApiWeb from '../../service/api-web'
import MensagensGerais from './MensagensGerais'
import Clientes from './Clientes'
import { ModulesEnum } from '@/store/actions/modulesEnum'
export default {
  name: 'CentralAtendimento',
  components: {
    MensagensGerais,
    Clientes
  },
  data: () => ({
    aba: 'MENSAGENS',
    idcliente: null,
    nomecliente: ''
  }),
  computed: {
    ...mapGetters(['getFornecedor', 'moduloFornecedorAtivo']),
    isMensagemActive() {
      return this.aba === 'MENSAGENS'
    },
    isClienteActive() {
      return this.aba === 'CLIENTES'
    },
    resourceActived() {
      return this.showComponente(ModulesEnum.VENDAMAIS_WEB)
    }
  },
  created() {
    if (this.showComponente(ModulesEnum.VENDAMAIS_WEB)) {
      this.sendAcesso()
    }
  },
  methods: {
    sendAcesso() {
      ApiWeb.v4.vendamais.acesso()
    },
    setAba(a) {
      this.aba = a
      this.idcliente = null
      this.nomecliente = ''
    },
    iniciarContato(e) {
      const id = _.get(e, 'idcliente')
      const nome = _.get(e, 'nomecliente')
      this.idcliente = id
      this.nomecliente = nome
      this.aba = 'MENSAGENS'
    },
    showComponente(modulos = []) {
      if (modulos && modulos.length > 0) {
        return this.moduloFornecedorAtivo(modulos)
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tab-container {
  margin: -20px -30px 20px -20px;
  text-align: center;
}
.tab {
  display: inline-block;
  width: 50%;
  padding: 10px;
  border-width: 0px 0px 5px 0px;
  border-style: solid;
  cursor: pointer;
}
.tab-active {
  border-width: 5px 0px 0px 0px;
}
.banner {
  width: 50%;
  border-radius: 2em 2em 2em 2em / 2em 2em 2em 2em;
}
</style>
